.button-default {
	border: 1px solid $main-green;
	margin: 0 auto;
	text-transform: uppercase;
	display: inline-block;
	padding: 5px 20px;
	font-size: 12px;
	margin-top: 20px;
	background: none;
	text-decoration: none;
	color: $main-green;
	transition: all 0.3s ease 0s;
	border-radius: $border-radius-default;
	&:hover {
		background-color: $main-green;
		color: #fff;
	}
	&.green {
		background-color: $main-green;
		color: #fff;
		transition: opacity 0.3s ease 0s;
		&:hover {
			opacity: 0.6;
		}
	}
}

.button-red {
	@extend .button-default;
	border-color: #b10000;
	color: #b10000;
	padding: 5px 10px;
	border-radius: $border-radius-default !important;
	&:hover {
		background-color: #b10000;
		color: #fff;
	}
}

.button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 15px 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px !important;
    font-size: 18px;
    color: #ffffff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: 3000;
}

.button-primary {
    background: -webkit-gradient(linear, left top, left bottom, from(#0085FF), to(rgba(52, 57, 130, 0))), #2E3F7F;
    background: -webkit-linear-gradient(top, #0085FF 0%, rgba(52, 57, 130, 0) 100%), #2E3F7F;
    background: -o-linear-gradient(top, #0085FF 0%, rgba(52, 57, 130, 0) 100%), #2E3F7F;
    background: linear-gradient(180deg, #0085FF 0%, rgba(52, 57, 130, 0) 100%), #2E3F7F;
}

.button-primary:hover {
    background: #2e3f7f;
}

.button-outline {
    border: 2px solid #2e3f7f;
    background-color: transparent;
    color: #2e3f7f;
}
