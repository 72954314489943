@import "bootstrap-4.3.1/scss/bootstrap_custom.scss";

//@import "_bootstrap.scss";
@import "_variables.scss";
@import "_buttons.scss";
@import "_msfavorites.scss";

a {
    cursor: pointer;  
}

.page-accordion__content {

}

.page-accordion__title.active + .page-accordion__content {     

}

.commodity-card__product-options_title {
    margin-top: 20px;
    flex-wrap: wrap;
}

.commodity-card__product-options_title_text {
    span + span {
        &::before {
            content: ',';
            margin-right: 5px;
        }
    }
}

.commodity-card__product-options_size {
    display: block;
}

img {
    max-height: initial;
}

.commodity-card__product-options_size_item {
    width: auto;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 10px;
}

.commodity-mobile__slider.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.commodity-card__product-options_color {
    padding-bottom: 10px; 
}

.commodity-card__btn_add[disabled] {
    opacity: 0.4;
}

.slick-track {
    .slick-slider {
        display: none;
        &:first-child {
            display: block;
        }
    }
}



.commodity-card__product-options_color {
    opacity: 0.4;

    li {
        opacity: 0.4;
        //display: none;
    }
}
.commodity-card__product-options_color {
    &.enable-filters {
        opacity: 1;
    }
    li.enabled {
        opacity: 1;
        display: inline-flex;
    }
}

#similar-products, #similar-product {
    padding-bottom: 0;  
}

.breakpoints__list {
    .breakpoints__item {
        white-space: normal;
    }
}

.commodity-card-mobile {
    .commodity-card__product-options_size_item {
        padding: 0 8px;
        font-size: 14px;
        height: 28px;
        margin-right: 5px;
    }
    .commodity-card__price_action.new {
        display: none;
    }

    .commodity-card__product-options_color {
        padding-bottom: 0;
    }
    .commodity-card__characteristic_img {
        display: none;
    }
    .commodity-card__title {
        margin-top: 20px;
    }
}

.commodity-card__price_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    .brand_image {
        max-width: 80px;
        display: none;  
    }
    .commodity-card__price_block_left {
        .commodity-card__count-message {
            margin-top: 0;
        }
    }
    .commodity-card__price_block_right {

    }   
    .commodity-card-mobile & {
        margin-top: 15px;
        .brand_image {
            max-width: 80px;
            display: block;  
        }
    } 
}

.commodity-card__slider-big, 
.commodity-card__slider-small,
.commodity-mobile__slider,
.commodity-mobile__slider_small {
    text-align: center;
    .slick-item {
        display: none;
        &:first-child {
            display: inline-block;
        }
    }   
    &.slick-initialized {
        .slick-item {
            display: inline-block;
        }    
    } 
}

.slick-loading .slick-list {
    background: #fff url("/front/site/img/ajax-loader.gif") center center no-repeat;
}

.header__catalog_content_list {
    max-height: initial;
}

.header__bot_nav_link:before {
    background-color: transparent;
    background: transparent;
}

.header__bot:hover {
    .header__bot_nav_link:before {
        background-color: transparent;
        background: #2e3f7f;
    }
}

.commodity-card__product-options_color {
    flex-wrap: wrap; 
}
.commodity-card__product-options_color_item {
    margin-bottom: 10px;
}

.commodity-card__product-options_color_box {
    border: 1px solid #555;
}

.commodity-card__product-options_size {
    opacity: 0.4;

    li {
        opacity: 0.4;
        //display: none;
    }
}
.commodity-card__product-options_size {
    &.enable-filters {
        opacity: 1;
    }
    li.enabled {
        opacity: 1;
        display: inline-flex;
    }
}

.commodity-card__order-list_item.active {
    .commodity-card__order-list_stock {
        color: #222 !important
    }
}

.debug {
    position: fixed;
    bottom: 0;
    left: 0;
    display: inline-block;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 20px 20px rgba(0,0,0,0.1);
    li {
        & + li {
            margin-top: 5px;
        }
    }
    span {
        display: inline-block;
        margin-left: 15px;
    }
}

.header__top_contacts_call {
    cursor: pointer;
}

.breakpoints {
    .breakpoints__list {
        .breakpoints__item {
            &.separ {
                margin: 0 5px;
            }
        }
    }
}

.header__top_nav_item.personal {
    display: list-item;
}

#office-profile-form {
    .message {
        display: block;
        position: static;
    }
    .has-error {
        input {
            border: 1px solid #b40a1b;
        }
    }
}

.lk-navigation {
    padding-top: 0;
}

.fancybox-container {
    z-index: 9000; 
}

.message {
    z-index: 9950;
}

.help-block.message {
    color: #b40a1b;
}

a.header__top_nav_link.link {
    cursor: pointer;
}

.page-contacts__list {
    font-size: 20px;
}

.search__icon {
    line-height: 0;
}

.page-brands__title {
    margin-top: 40px;
    font-weight: 500;
    font-size: 24px;
}

.page-brands__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin-top: 20px;
}

.page-brands__card:hover .page-brands__card_label {
    color: #2697ff;
}

.page-brands__card_img {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid #eee;
    img {
        width: auto;
    }
}

.page-brands__grid_item {
    a {
        display: block;
    }
    
}

.page-brands__card_label {
    margin-top: 10px;
    font-size: 20px;
    color: #2e3f7f;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


// .categories__grid_item {
//     &.item-8 {
//         grid-area: item-8;
//     }
//     &.item-9 {
//         grid-area: item-9;
//     }
//     &.item-10 {
//         grid-area: item-10;
//     }
//     &.item-11 {
//         grid-area: item-11;
//     }
//     &.item-12 {
//         grid-area: item-12;
//     }
//     &.item-13 {
//         grid-area: item-13;
//     }
//     &.item-14 {
//         grid-area: item-14;
//     }
//     &.item-15 {
//         grid-area: item-15;
//     }
//     &.item-16 {
//         grid-area: item-16;
//     }
// }

// .categories__grid {
//     grid-template-areas:
//     "item-1 item-1 item-2 item-2 item-2 item-2"
//     "item-3 item-3 item-4 item-4 item-5 item-5"
//     "item-6 item-6 item-6 item-7 item-7 item-7"
//     "item-8 item-8 item-9 item-9 item-10 item-10"
//     "item-11 item-11 item-12 item-12 item-13 item-13"
//     "item-14 item-14 item-15 item-15 item-16 item-16";
// }

.about__list_item::before {
    background-image: url('/front/site/img/svg/about-check.svg');
}

.about__bot_left img {
    max-height: initial;
}

.page-contacts__map_grid_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.bootstrap .form-group {
    grid-gap: 0 !important;
}

.header-mobile__bot {
    .logo {
        img {
            max-height: 40px;
        }
    }
}

@media (max-width: 1140px) {
    .page-brands__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        margin-top: 10px;
    }

    .page-brands__title {
        font-size: 20px;
    }

    .page-brands__card_label {
        margin-top: 5px;
        font-size: 16px;
    }

    .page-brands__card_img {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        img {
            width: auto;
            margin: 10px;
        }
    }
}

#mslistorder-output {
    border-radius: 5px;
}

.commodity-card__featured {
    white-space: nowrap;
    min-height: 25px;
    display: flex;
    align-items: center;
    align-content: center;
}

.msfavorites .msfavorites-text-load, .msfavorites.load .msfavorites-text-add, .msfavorites.load.voted .msfavorites-text-remove {
    //display: flex !important;
}

.commodity-card__characteristic_img {
    max-width: 150px;
}

.dealer__slider {
    .dealer__slider_item {
        img {
            max-width: 80%;
        }
    }
}

@import "_shop.scss";
@import "_content.scss";      