.content {
    font-size: 20px;
    line-height: 1.5;

    h2,h3,h4 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    p {
        margin: 20px 0;
    }

    ol {
        margin: 10px 0 20px 25px;
        padding: 0;
        li {

        }
    }

    ul {
        position: relative;
        padding-left: 25px;
        li {
            &::before {
                content: '\2014';
                position: absolute;
                left: 0;
            }
        }
    }
}
