h2.order-header {
    font-size: 26px;
}

.ms2-message-success {
    background: green !important;
    font-size: 14px !important;
}

.card__price_old {
    font-size: 16px;
}

.card__price_new {
    font-size: 20px;
}

#msCart {
    margin-top: 20px;
    table {
        tbody {
            tr.tbl-header {
                th {

                }
            }
            tr {
                td {
                    &.title {
                        .title {
                            a {
                                color: inherit;
                                &:hover {
                                    color: $main-green;
                                }
                            }
                        }
                    }
                    &.count {}
                }
            }
        }
    }
    .next-step {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        button {
        }
        a {
           
        }
    }
    .tbl {
        .tr {
            display: flex;
            align-items: center;
            align-content: center;
            &.tbl-header {
                background-color: $table-header-color;
                display: none;
            }
            .th, .td {
                flex-grow: 1;
                padding: 10px;
                &.image {
                    flex-basis: 10%;
                }
                &.title {
                    flex-basis: 40%;
                    
                }
                &.count {
                    flex-basis: 20%;
                }
                &.price {
                    flex-basis: 20%;
                }      
                &.remove {
                    flex-basis: 10%;
                }          
            }
            .th {
                font-weight: 500;
                text-align: center;
                &.price {

                }
            }
            .td {
                &.image {
                    a {
                        display: inline-block;
                        //border: 1px solid $border-color;
                        border-radius: $border-radius-default;       
                        overflow: hidden;  
                        line-height: 0;               
                    }
                }
                &.title {
                    a {
                        color: #000;
                        cursor: pointer;
                        &:hover {
                            color: $main-green;
                        }
                    }
                    .small {
                        margin-top: 5px;
                        font-size: 14px;
                        color:
                        #656565;
                    }
                }
                &.count {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    input {
                        color: #000;
                    }
                    .modal-details__list_item_count {                        
                        max-width: 100px;
                    }
                    // form {
                    //     border: 1px solid $border-color;
                    //     border-radius: $border-radius-default;
                    //     overflow: hidden;
                    //     display: inline-flex;
                    //     align-content: center;
                    //     .minus, .plus {                          
                    //         background-color: #fcfcfc;
                    //         margin: 0;
                    //         font-size: 18px;
                    //         text-align: center;
                    //         padding: 6px 10px;
                    //         flex-shrink: 0;
                    //         cursor: pointer;
                    //         transition: background-color .3s ease 0s;
                    //         border: none;
                    //         box-shadow: none;
                    //         &:hover {
                    //             background-color: #f3f3f3;
                    //         }
                    //     }
                    //     input {
                    //         width: 40px;
                    //         text-align: center;
                    //         border: none;
                    //         background: none;
                    //         box-shadow: none;
                    //         margin: 0;
                    //         padding: 0;
                    //         font-weight: 600;
                    //         flex-shrink: 0;
                    //         &::-webkit-outer-spin-button,
                    //         &::-webkit-inner-spin-button {
                    //             -webkit-appearance: none;
                    //         }    
                    //         -moz-appearance: textfield;                        
                    //     }
                    // }
                }
                &.price {
                    text-align: center;
                    span {
                        white-space: nowrap;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
                &.remove {
                    button {
                        margin-top: 0;
                        border: none;
                        opacity: 1;
                        img, svg {
                            width: 10px;
                            height: 10px;
                        }
                        svg {
                            fill: #222;
                        }
                        &:hover {
                            background: none;
                        }
                    }
                }
            }
            &.body + .tr.body {
                //border-top: 1px solid #eaeaea;  
                margin-top: 15px;                        
            }
            &.body {
                border: 1px solid #C4C4C4;
                padding: 10px 20px;      
                border-radius: 5px;
            }
            &.tbl-footer {
                background-color: $table-header-color;
                border: $table-header-color;
                margin-top: 15px;
                padding: 10px 20px;
                border-radius: 5px;
                .title {
                    text-align: left;
                    font-size: 20px;
                    flex-basis: 50%;
                }
                .count {
                    flex-basis: 20%;
                    font-size: 20px;
                }
                .price {
                    flex-basis: 20%; 
                    font-size: 20px;
                }
            }
        }
    }
}

#msOrder {
    margin-top: 0;
    border: 1px solid #C4C4C4;
    padding: 30px;
    border-radius: 5px;
    //box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    h5 {
        font-weight: 600;
        font-size: 18px;
        border: 1px solid $orderform-header-color;
        padding: 10px 20px;
        text-align: center;
        color: #000;
        margin-bottom: 20px;
        border-radius: 5px;
        margin-top: 30px;
        background-color: $orderform-header-color;
        &:first-child {
            margin-top: 0;
        }
    }
    .label {
        //font-weight: 500;
        color: #000;
    }
    .info {
        font-size: 14px;
        margin-top: 5px;
        font-style: italic;
    }
    .total {
        h4 {
            color: #000;
            text-align: center;
            span {
                white-space: nowrap;
            }
        }
    }
    input, textarea {
        border: 1px solid #949494;
        border-radius: 5px;
        color: #000;
    }
}

#mse2_filters {
    .hidden {
        display: none;
    }
    button[type="reset"] {
        margin-top: 0;
        padding: 8px 20px;
        width: 100%;
    }
    .sidebar__block {
        margin-left: 0;
        margin-right: 0;

        li.active {
            a {
                color: #2e3f7f;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .sidebar__title {

        }
        .mse2_number_slider {
            margin-top: 30px;
            margin-bottom: 20px;
            .ui-slider-handle {
                left: 6px;
                & + .ui-slider-handle {
                    left: calc(100% - 6px);
                }
            }
        }
        .mse2_number_inputs {
            label.mse2_number_label_0 {
                input {
                    text-align: left;
                }
            }
            label.mse2_number_label_1 {
                input {
                    text-align: right;
                }
            }
        }

        .ui-slider-range {
            background: #2e3f7f;
        }
    }
}

#mse2_selected_wrapper {
    min-height: auto !important;
    margin: 0 !important;
    #mse2_selected {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-right: 0px;
        padding: 0;
        background: none;
        flex-wrap: wrap;
    }
    strong {
        margin-right: 10px;
        font-weight: 500;
        font-size: 20px;
        margin-top: 25px;
    }
    .mse2_selected_link {
        height: 32px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 0 15px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: #2e3f7f;
        font-size: 18px;
        color: #ffffff;
        margin-right: 10px;
        margin-top: 25px;
        &:hover {
            background-color: #b40a1b;
        }
        em {
            font-style: normal;
            color: #fff !important;
        }
        sup {
            margin-left: 5px;
            font-size: 25px;
        }
        & + strong {
            margin-left: 10px;
        }
    }
}

.catalog__categories {
    margin-bottom: 20px;
}

.msfavorites {
    &.card__featured, &.commodity-mobile__featured { 
        .active {
            svg {
                fill: #fff;
            }        
        }

        &.voted {
            svg {
                fill: #b40a1b;
            }
        }  
    }
}


.commodity-card {
    .notvue {
        visibility: hidden;
        display: none;  
    }
}


table.sizes-chart {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    th, td {
        border: 1px solid #C4C4C4;
        text-align: center;
        vertical-align: middle;
        color: #222;
        font-size: 15px;
        font-weight: normal;
        width: 2%;
        padding: 8px 0;
    }
    th {
        font-weight: 500;
    }
}
  
@media (max-width: 1141px) {
    #mse2_selected_wrapper {
        margin-bottom: 40px !important;
    }

    table.sizes-chart {
        th, td {
            font-size: 11px;
            padding: 5px 0;
        }
    }
}
@media (max-width: 991px) {
    #msCart {
        .tbl {
            .tr.body {
                .td.title {
                    a {

                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    h2.order-header {
        font-size: 24px;
    }

    #msCart {
        .tbl {
            .tr {
                display: block;
                position: relative;

                .td.image {
                    margin-right: 50px;
                }

                .td.title {
                    a {
                        font-weight: 400;
                    }
                }

                .td.count {
                    text-align: left;
                }

                .td.price {
                    text-align: left;
                    color: #333; 
                }

                .td.remove {
                    position: absolute;
                    right: 0;
                    top: 10px;
                }
            }

            .tr.tbl-header {
                display: none;
            }

            .tr.body {
                padding: 10px 0;
            }

            .tr.tbl-footer {
                margin-top: 20px;
                padding: 0;

                .th {
                    text-align: left !important;
                    display: inline-block;
                }

                .th.image {
                    display: none;
                }

                .th.remove {
                    display: none;
                }
            }
        }
    }
    #msOrder {
        padding: 15px; 
    }

    #mse2_results {

    }
}